import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { motion } from "framer-motion";
import "./StudentProfile.css";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAssignedStudents } from "../../config/restApiAuth";
import { useCurrentTrainer } from "../../hooks/useCurrentTrainer";
import { useNavigate } from "react-router-dom";
import AttendanceModal from "../../components/AttendanceModal/AttendanceModal";
import { useMarkAttendence } from "./useMarkAttendence";

const StudentProfile = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [activeTab, setActiveTab] = useState(0); // 0 for Individual, 1 for Group
  const queryClient = useQueryClient();

  // Trainer-related data
  const { isPending: isTrainerLoading, name, trainerId } = useCurrentTrainer();
  const { markAttendenceMutate, isLoading } = useMarkAttendence();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isEnabled, setIsEnabled] = useState(false);

  // Function to check if the current time is within the allowed intervals
  const checkButtonState = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Check intervals (e.g., 8:55–9:05, 9:25–9:35, etc.)
    if (
      (minutes >= 55 && minutes <= 59) || // :55 to :59
      (minutes >= 0 && minutes <= 5) || // :00 to :05
      (minutes >= 25 && minutes <= 35) // :25 to :35
    ) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  useEffect(() => {
    // Initial check
    checkButtonState();

    // Set an interval to check every second
    const interval = setInterval(() => {
      checkButtonState();
    }, 1000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  // Get schedules and filter for students based on activeTab
  let schedules = queryClient.getQueryData(["CurrTrainer"]);
  schedules = schedules?.trainers[0]?.classes || [];

  // Separate individual and group student arrays
  const studentIndividualArrayUnique = schedules
    ?.filter((s) => {
      const inputTime = `${s.end_date}T${s.end_time}`;
      const inputDate = new Date(inputTime);

      const currentDate = new Date(); // Current date/time in system timezone

      // Convert currentDate to IST (offset: +5:30)
      const currentIST = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);
      return (
        s.class_type === "Individual" && s.is_booked && inputDate >= currentIST
      );
    })
    ?.map((s) => s.students)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index); // Unique
  console.log(studentIndividualArrayUnique, "studentIndividualArrayUnique");
  const studentGroupArrayUnique = schedules
    ?.filter((s) => s.class_type === "Group" && s.is_booked)
    ?.map((s) => s.students)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index); // Unique

  // Determine the current student type based on activeTab
  const studentType =
    activeTab === 0 ? studentIndividualArrayUnique : studentGroupArrayUnique;

  // Fetch assigned students based on the active tab
  const { isPending: isStudentsLoading, data: assignedStudents } = useQuery({
    queryKey: ["students", activeTab],
    queryFn: () => getAssignedStudents({ students: studentType }),
    enabled: !!studentType?.length, // Fetch only if studentType has data
  });
  console.log(assignedStudents, "assignedStudents");

  function handleMarkAttendance(date, status, student) {
    console.log(date, status, student, trainerId);
    markAttendenceMutate({ student, trainerId });
    // console.log(`Attendance marked as ${status} for ${date}`);
    //1) studentId with schedules
    //2) filter schedule with trainerId
  }

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update activeTab (0 for Individual, 1 for Group)
    setCurrentPage(0); // Reset pagination
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Filter students based on search term
  const filteredStudents =
    assignedStudents?.assignedStudents?.filter((student) => {
      return (
        student.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.course?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }) || [];
  console.log(filteredStudents, "filteredStudents");
  // const schedule = filteredStudents?.
  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div style={{ display: "flex" }} className="StudentProfileSection">
      <Sidebar />
      <div
        className="student-profile-container"
        style={{ width: "100%", padding: "20px", height: "110vh" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            margin: "0",
          }}
          className="Profile_Header"
        >
          <Typography
            variant="h5"
            className="dynamic-title"
            sx={{
              fontWeight: "800",
              fontFamily: "'Nunito Sans', sans-serif",
              color: "black",
            }}
          >
            Assigned Students
          </Typography>

          {/* Tabs for Individual and Group */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="student type tabs"
            >
              <Tab label="Individual" />
              <Tab label="Group" />
            </Tabs>
          </Box>

          <motion.input
            type="text"
            placeholder="Search by name or course..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
            whileFocus={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          />
        </div>

        <motion.div
          className="table-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <TableContainer component={Paper} className="table-paper">
            <Table aria-label="students table">
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Progress</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStudents
                  .slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  )
                  .map((student) => (
                    <TableRow key={student._id} hover>
                      <TableCell>
                        <Tooltip title={student.name} arrow>
                          <span>{student.name || "No Name"}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {student.courses.at(-1) || "No Course"}
                      </TableCell>
                      <TableCell>
                        <div className="progress-bar">
                          <div
                            className="progress"
                            style={{ width: `${student.progress || 0}%` }}
                          ></div>
                        </div>
                        <span>{student.progress || 0}%</span>
                      </TableCell>
                      <TableCell align="center">
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="action-buttons"
                        >
                          <Button
                            variant="outlined"
                            onClick={() =>
                              navigate(
                                `/assignmentmanagement?id=${student._id}`
                              )
                            }
                            sx={{ marginRight: "10px" }}
                          >
                            Assignments
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => setIsModalOpen(true)}
                            disabled={
                              isTrainerLoading ||
                              isStudentsLoading ||
                              !isEnabled
                            }
                          >
                            Attendance
                          </Button>
                          <AttendanceModal
                            open={isModalOpen}
                            onClose={handleClose}
                            student={student}
                            onMarkAttendance={handleMarkAttendance}
                          />
                        </motion.div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredStudents.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[6]}
            className="pagination"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default StudentProfile;
