import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-main.png";
import "./Sidebar.css";
import React from "react";
import { useLogout } from "../../authentication/useLogout";

const navItems = [
  { name: "dashboard", displayName: "Home", route: "/trainerdashboard" },
  { name: "library_books", displayName: "Courses Assigned", route: "/assignedcourse" },
  { name: "local_library", displayName: "Study Materials", route: "/studymaterial" },
  { name: "leaderboard", displayName: "Student Analytics", route: "/students" },
  // { name: "forum", displayName: "Discussion Forum", route: "/discussion" },
  { name: "person", displayName: "Profile", route: "/profile" },
  { name: "logout", displayName: "Logout", route: "/" }
];

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout: trainerLogout, isLoading } = useLogout(); 

  return (
    <section className="page sidebar-2-page">
      <aside className={`sidebar-2 ${isOpen ? "open" : ""}`}>
        <div className="inner">
          <header>
            <button
              type="button"
              className="sidebar-2-burger sidebar-btn"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="material-symbols-outlined">
                {isOpen ? "close" : "menu"}
              </span>
            </button>
            <img src={logo} width="115" height="50" alt="logo" />
          </header>
          <nav>
            {navItems.map((item) => (
              <Link
                to={item.route}
                key={item.name}
                onClick={item.name === "logout" ? trainerLogout : null} // Logout only for 'logout'
                className="sidebar-btn"
              >
                <span className="material-symbols-outlined">{item.name}</span>
                <p>{item.displayName}</p> {/* Custom display names */}
              </Link>
            ))}
          </nav>
        </div>
      </aside>
    </section>
  );
};
