import { useMutation } from "@tanstack/react-query";
import { markAttendence } from "../../config/restApiAuth";
import { toast } from "react-hot-toast";

export function useMarkAttendence() {
  const mutate = useMutation({
    mutationFn: ({ student, trainerId }) =>
      markAttendence({ student, trainerId }),
    onSuccess: (response) => {
      if (!response.success) {
        toast.error("Something went wrong!");
      } else {
        toast.success("Attendence Marked!");
      }
    },
    onError: (error) => {
      // console.log(error);
      toast.error("Something went wrong!");
    },
  });
  let { mutate: markAttendenceMutate, isPending: isLoading } = mutate;
  return { markAttendenceMutate, isLoading };
}
