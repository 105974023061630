import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./AttendanceModal.css";

const AttendanceModal = ({ open, onClose, student, onMarkAttendance }) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleAttendance = (status) => {
    onMarkAttendance(selectedDate, status, student);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal-container">
        <Box className="modal-header">
          <Typography variant="h6" component="h2" className="modal-title">
            Mark Attendance
          </Typography>
          <IconButton onClick={onClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="modal-content">
          <TextField
            label="Date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Box className="button-container">
            <Button
              variant="contained"
              color="success"
              onClick={() => handleAttendance("Present")}
            >
              Mark Present
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleAttendance("Absent")}
            >
              Mark Absent
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AttendanceModal;
