import { API_BASE_URL } from "../config/constant.js";

export async function createAssignment({
  assignment_title,
  assignment_description,
  submission_deadline,
  assignment_link,
  studentId,
  trainerId,
}) {
  try {
    console.log(
      assignment_title,
      assignment_description,
      submission_deadline,
      assignment_link
    );
    //
    const uri = `${API_BASE_URL}assignments/createAssignments`;
    const createAssignment = await fetch(uri, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        assignment_title,
        assignment_description,
        submission_deadline,
        assignment_link,
        studentId,
        trainerId,
      }),
      credentials: "include",
    });
    const data = await createAssignment.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error(error.message);
  }
}

export async function getAssignments({ id }) {
  try {
    //
    const uri = `${API_BASE_URL}assignments/getStudentAssignments/${id}`;
    const studentAssignment = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await studentAssignment.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error("Can't fetch assignments!", 400);
  }
}
