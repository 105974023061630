import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import StudentProfile from '../src/pages/StudentProfile/StudentProfile';
import TrainerDashboard from './pages/TrainerDashboard/TrainerDashboard';
import LoginPage from './pages/LoginPage/LoginPage';
import AssignedCourse from './pages/AssignedCourse/AssignedCourse';
import AssignedCourseDetails from './pages/AssignedCourseDetails/AssignedCourseDetails';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProtectedRoute from './authentication/protectedRoute';
import AppLayout from './components/AppLayout';
import AssignmentManagement from './pages/AssignmentManagement/AssignmentManagement';
import UnitDetail from './components/UnitDetail/UnitDetail';
import ProfileSectionPage from './pages/ProfileSectionPage/ProfileSectionPage';
import AssignmentViewDetails from './pages/AssignmentViewDetails/AssignmentViewDetails';
import DiscussionPage from './pages/DiscussionPage/DiscussionPage'
import QuestionDetail from './components/QuestionDetail/QuestionDetail';
import questionsData from './components/questionsData';
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import StudyMaterialMain from './components/StudyMaterialComponents/StudyMaterialMain/StudyMaterialMain';


function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0
      }
    }
  })
  return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
          <Router>
            <Routes>
              <Route element={<ProtectedRoute>
                <AppLayout />
              </ProtectedRoute>}>
                <Route exact path='/trainerdashboard' element={<TrainerDashboard />} />
                <Route exact path='/students' element={<StudentProfile />} />
                <Route exact path='/studymaterial' element={<StudyMaterialMain />} />
                <Route exact path='/assignedcourse' element={<AssignedCourse />} />
                <Route exact path='/assignedcoursedetails/:courseid' element={<AssignedCourseDetails />} />
                <Route exact path='/assignmentmanagement' element={<AssignmentManagement />} />
                <Route exact path='/assignmentview' element={<AssignmentViewDetails />} />
                <Route exact path='/unit/1' element={<UnitDetail />} />
                <Route exact path='/profile' element={<ProfileSectionPage />} />
                <Route exact path='/discussion' element={<DiscussionPage />} />
                <Route path="/questions/:questionId" element={<QuestionDetail questions={questionsData} />} />
              </Route>
              <Route exact path='/' element={<LoginPage />} />
            </Routes>
          </Router>
          <Toaster
            position="top-center"
            gutter={12}
            containerStyle={{ margin: "8px" }}
            toastOptions={{
              success: {
                duration: 3000,
              },
              error: {
                duration: 5000,
              },
              style: {
                fontSize: "16px",
                maxWidth: "500px",
                padding: "16px 24px",
                backgroundColor: "white",
                color: "black",
              },
            }}
          />
      </QueryClientProvider>
  );
}

export default App;
