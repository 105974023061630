import React from 'react'
import MaterialLinkCard from '../MaterialLinkCard'
import { Sidebar } from '../../Sidebar/Sidebar'


const StudyMaterialMain = () => {
  return (
    <div style={{width:'100%',display: 'flex'}} className='studymaterialmain-section'>
        <Sidebar />
        <MaterialLinkCard />
    </div>
  )
}

export default StudyMaterialMain
