import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { MdEventNote } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./UpcomingClasses.css";
import { Card } from "react-bootstrap";

const UpcomingClasses = ({ upcomingClasses }) => {
  return (
    <div className="upcoming-classes-container">
      <Typography variant="h5" component="div" gutterBottom className="title">
        Today's Upcoming Classes
      </Typography>
      <TableContainer component={Card} className="table-container">
        <Table aria-label="upcoming classes table">
          <TableHead>
            <TableRow className="table-header">
              <TableCell>
                <MdEventNote /> Class Name
              </TableCell>
              <TableCell>
                <AiOutlineClockCircle /> Time
              </TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingClasses?.map((classItem) => (
              <TableRow key={classItem._id} hover className="table-row">
                <TableCell>
                  <Tooltip title={classItem.class_type} arrow>
                    <div className="text-ellipsis">{classItem.course}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {new Date(
                    `${classItem.start_date}T${classItem.start_time}`
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                  -{" "}
                  {new Date(
                    new Date(
                      `${classItem.start_date}T${classItem.start_time}`
                    ).setMinutes(
                      new Date(
                        `${classItem.start_date}T${classItem.start_time}`
                      ).getMinutes() + 30
                    )
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell>
                  <Tooltip title={classItem.description} arrow>
                    <div className="text-ellipsis">{classItem.class_type}</div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UpcomingClasses;
