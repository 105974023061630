import React from "react";
import "./AssignmentViewDetails.css";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import AssignmentViewDetailsInner from "../../components/AssignmentViewDetailsInner/AssignmentViewDetailsInner";
import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAssignments } from "../../hooks/apiAssignment";

function AssignmentViewDetails() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const queryClient = useQueryClient();
  const studentId = queryClient.getQueryData(["user"])?.user?._id;
  const { isPending: isLoading, data: assignment } = useQuery({
    queryFn: () => getAssignments({ id }),
  });

  return (
    <div
      style={{ display: "flex" }}
      className="assignment-view-details-container"
    >
      <Sidebar />
      <AssignmentViewDetailsInner assignmentId={assignment} />
    </div>
  );
}

export default AssignmentViewDetails;
